import Vue from "vue";
import Vuex, { StoreOptions, MutationTree, ActionTree, GetterTree } from "vuex";

import http from "@/http";

import {
  User,
  RootState,
  Process,
  Company,
  Store,
  Family,
  Proposal,
} from "./interfaces";

Vue.use(Vuex);

const token = localStorage.getItem("token");

const mutations: MutationTree<RootState> = {
  setUser(state, user: User) {
    state.user = user;
  },
  setProcesses(state, processes: Process[]) {
    state.processes = processes;
  },
  setCompanies(state, companies: Company[]) {
    state.companies = companies;
  },
  setStores(state, stores: Store[]) {
    state.stores = stores;
  },
  setFamilies(state, families: Family[]) {
    state.families = families;
  },
  setProposals(state, proposals: Proposal[]) {
    state.proposals = proposals;
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  setToken(state, token: string) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  startLoading(state) {
    state.loading++;
  },
  endLoading(state) {
    state.loading--;
  },
};

const actions: ActionTree<RootState, RootState> = {
  async init({ commit }) {
    const { data: user } = await http.get<User>("me");
    const { data: stores } = await http.get<Store[]>("stores");
    const { data: processes } = await http.get<Process[]>("processes");
    const { data: companies } = await http.get<Company[]>("companies");
    const { data: families } = await http.get<Family[]>("families");
    const { data: proposals } = await http.get<Proposal[]>("proposals");

    commit("setUser", user);
    commit("setStores", stores);
    commit("setProcesses", processes);
    commit("setCompanies", companies);
    commit("setFamilies", families);
    commit("setProposals", proposals);
  },
  async login({ commit }, { id_token }) {
    const { data } = await http.post("login", {
      id_token,
    });

    commit("setToken", data.access_token);
  },
};

const getters: GetterTree<RootState, RootState> = {
  stores: (state: RootState) => {
    if (state.user == null) {
      return [];
    }
    const user = state.user;

    if (user.admin) {
      return user.admin.stores;
    } else {
      return [];
    }
  },
};

const store: StoreOptions<RootState> = {
  state: {
    token: token == null ? "" : token.toString(),
    user: undefined,
    processes: [],
    stores: [],
    companies: [],
    families: [],
    proposals: [],
    snackbar: {
      show: false,
      color: "info",
      content: "",
    },
    loading: 0,
  },
  mutations,
  actions,
  getters,
};

export default new Vuex.Store(store);
