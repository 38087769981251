import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// import * as Sentry from "@sentry/vue";

// Sentry.init({
//   Vue: Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN
// });

Vue.config.productionTip = false;

if (module.hot) {
  module.hot.accept();

  module.hot.addStatusHandler(status => {
    // eslint-disable-next-line
    if (status === "prepare") console.clear();
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
