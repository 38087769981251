import axios from "axios";

import store from "@/store";

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`
});

http.interceptors.request.use(
  config => {
    const { token } = store.state;

    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    store.commit("startLoading");

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    const token = response.headers["Authorization"] || response.data["token"];

    if (token) {
      store.commit("setToken", token);
    }

    store.commit("endLoading");

    return response;
  },
  error => {
    if (error.response == null) {
      return Promise.reject(error);
    }

    const { status } = error.response;

    if (status === 401) {
      store.commit("setToken", "");

      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default http;
